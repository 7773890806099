const Cart = {
    items: [],
    total: 0,
    count: 0,
    deliveryPrice: 0,
    minimumPrice: 0,
    discount: 0,
    coupon: null,


    add(product) {
        api.post('/cart', product).then(() => {
            this.get();
            success("Ürün sepete eklendi");
        }).catch((error) => {
            warn(error.response.data.message);
        });
    },
    useCoupon(code) {
        api.post('/cart/useCoupon', {code: code}).then(response => {
            console.log(response)
            if (response.data.status === "success") {
                success(response.data.message)
                document.dispatchEvent(cartUpdated)
            } else {
                warn(response.data.message)
            }
        }).catch((error) => {
            warn(error.data.message);
        });
    },

    get() {
        api.get('/cart').then(response => {
            this.items = response.data.items
            this.total = response.data.total
            this.count = response.data.items.length
            this.deliveryPrice = response.data.deliveryPrice
            this.discount = response.data.discount
            this.coupon = response.data.coupon
            this.minimumPrice = response.data.minimum_purchase_amount
            document.dispatchEvent(cartUpdated)
        });
    },

    emptyCart() {
        api.delete('/cart').then(response => {
            location.reload();
        });
    },


    quantity: {
        increase(product) {
            api.put('/cart', {quantity: 1, id: product.id}).then(() => {
                Cart.get();
            });
        },
        decrease(product) {
            api.put('/cart', {quantity: -1, id: product.id}).then(() => {
                Cart.get();
            });
        }
    },

}
export default Cart
