const Communicate = {
    URL: window.location.origin + '/service',
    async get(endpoint) {
        return await axios.get(this.URL + endpoint)
    },

    async post(endpoint, data) {
        return await axios.post(this.URL + endpoint, data)
    },

    async put(endpoint, data) {
        return await axios.put(this.URL + endpoint, data)
    },

    async delete(endpoint) {
        return await axios.delete(this.URL + endpoint)
    }
}

export default Communicate
