import './bootstrap';
import Communicate from './Communicate'
import Cart from './Cart'

window.cartUpdated = new CustomEvent('cart:updated')

window.api = Communicate;
window.Cart = Cart
Cart.get();

document.addEventListener('cart:updated', () => {
    // Clear the cart list
    document.querySelector('.cart-list').innerHTML = '';
    for (let item of Cart.items) {
        // Create the cart item line
        let cartItemLine = document.createElement('li');
        cartItemLine.classList.add('product-box-contain');

        // Create the cart item
        let cartItem = document.createElement('div');
        cartItem.classList.add('drop-cart');

        // Create the cart item image
        let cartItemImage = document.createElement('a');
        cartItemImage.href = '#!';
        cartItemImage.classList.add('drop-image');
        let image = document.createElement('img');
        image.src = item.image;
        image.classList.add('blur-up', 'lazyloaded');
        image.alt = item.name;
        cartItemImage.append(image);

        // Create the cart item details container
        let cartItemContain = document.createElement('div');
        cartItemContain.classList.add('drop-contain');
        let cartItemName = document.createElement('a');
        cartItemName.href = '#!';

        //Cart item name
        let name = document.createElement('h5');
        name.textContent = item.name;
        cartItemName.append(name);

        //Cart item pricing
        let cartItemPrice = document.createElement('h6');
        let price = document.createElement('span');

        //Add cart item quantity
        price.textContent = item.quantity + ' x ';
        cartItemPrice.append(price);

        //Set the total price
        let total = document.createElement('span');
        total.textContent = '₺' + item.total;
        cartItemPrice.append(total);


        //Append the cart item details to the cart item container
        cartItemContain.append(cartItemName);
        cartItemContain.append(cartItemPrice);
        cartItem.append(cartItemImage);
        cartItem.append(cartItemContain);
        cartItemLine.append(cartItem);

        //Append the cart item to the cart list
        document.querySelector('.cart-list').append(cartItemLine);

        //format money
        let cartTotal = Cart.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

        document.getElementById('cart-total').textContent = '₺' + cartTotal;
    }

});
